export const BACKEND_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_BACKEND_ROOT
        : (window as any).REACT_APP_BACKEND_ROOT // eslint-disable-line @typescript-eslint/no-explicit-any

export const PUBLIC_AUTH =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_PUBLIC_AUTH
        : (window as any).REACT_APP_PUBLIC_AUTH // eslint-disable-line @typescript-eslint/no-explicit-any

export const LOGOUT_PATH =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_LOGOUT
        : (window as any).REACT_APP_LOGOUT // eslint-disable-line @typescript-eslint/no-explicit-any

export const API_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ROOT
        : (window as any).REACT_APP_API_ROOT // eslint-disable-line @typescript-eslint/no-explicit-any

export const SUBSCRIPTIONS_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_SUBSCRIPTIONS_ROOT
        : (window as any).REACT_APP_SUBSCRIPTIONS_ROOT // eslint-disable-line @typescript-eslint/no-explicit-any

export const GRAPHQL_PATH =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_GRAPHQL_PATH
        : (window as any).REACT_APP_GRAPHQL_PATH // eslint-disable-line @typescript-eslint/no-explicit-any

export const GOOGLE_CLIENT_ID =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_GOOGLE_CLIENT_ID
        : (window as any).GOOGLE_CLIENT_ID // eslint-disable-line @typescript-eslint/no-explicit-any

export const FACEBOOK_APP_ID =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_FACEBOOK_APP_ID
        : (window as any).FACEBOOK_APP_ID // eslint-disable-line @typescript-eslint/no-explicit-any
