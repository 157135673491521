import styles from './index.styles'

import React, { useState, MouseEvent } from 'react'

import { Box, ListItemText, MenuItem, Menu, Button } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'

import { ScannerType } from 'api/generated'
import { Loader } from '../../layout'

interface SelectScannerButtonProps {
    scanners: ScannerType[]
    onSelect: (selected: ScannerType, source: string) => void
    loading: boolean
    disabled: boolean
}

const SelectScannerButton = ({
    scanners,
    onSelect,
    loading,
    disabled,
}: SelectScannerButtonProps) => {
    const classes = styles()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleSelect = (scanner: ScannerType, source: string) => {
        handleClose()
        onSelect(scanner, source)
    }

    return (
        <Box>
            <Button
                disabled={disabled || loading}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={classes.button}
                variant="outlined"
                endIcon={loading ? <Loader /> : <ArrowForwardIos />}
            >
                Scan more
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {scanners.map(s => (
                    <>
                        <MenuItem disabled key={s.id}>
                            {`${s.model} (${s.type}), ${s.vendor}`}
                        </MenuItem>
                        {s.sources?.map(
                            (source: string | undefined | null, i) => (
                                <MenuItem
                                    onClick={() =>
                                        handleSelect(s, source as string)
                                    }
                                    key={source as string}
                                >
                                    <ListItemText inset>{source}</ListItemText>
                                </MenuItem>
                            )
                        )}
                    </>
                ))}
            </Menu>
        </Box>
    )
}

export default SelectScannerButton
