import { useMutation } from '@apollo/client'

import {
    ScanDeleteDocument,
    ScanCreateDocument,
    ScanCreateInput,
} from 'api/generated'

export const useScanAPI = () => {
    const [create] = useMutation(ScanCreateDocument)
    const [remove] = useMutation(ScanDeleteDocument)

    const createScan = (data: ScanCreateInput) =>
        create({ variables: { input: data } }).then(
            response => response.data?.scanCreate
        )

    const deleteScan = (id: string) =>
        remove({ variables: { input: { scanId: id } } }).then(
            response => response.data?.scanDelete?.success
        )

    return { deleteScan, createScan }
}
