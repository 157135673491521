import styles from './index.styles'

import { FC } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { BATCH_TYPES } from 'utils/types.utils'
import { KeyValType } from '../../../../../api/generated'

interface CellWithButtonsProps {
    type: KeyValType
}

const CellWithBatchType: FC<CellWithButtonsProps> = ({
    type,
}: CellWithButtonsProps) => {
    const classes = styles({ type: type.key as BATCH_TYPES })
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            className={classes.root}
        >
            <Box className={classes.dot} />
            <Typography className={classes.title} variant="body2">
                {type.val}
            </Typography>
        </Stack>
    )
}

export default CellWithBatchType
