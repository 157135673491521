import styles from './index.styles'

import React, { useState } from 'react'

import { Button, Divider, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Summarize } from '@mui/icons-material'

import { PageLayout } from 'components/layout'
import { ScanToolbar, Table, ScanOptions } from 'components/common'
import { appPaths } from 'routes'
import { DateField } from 'components/inputs'
import { useClaims } from 'hooks/claims'
import { useScanners } from 'hooks/config'
import { formatDateToDateString } from 'utils/date.utils'
import { useScanAPI } from 'hooks/scans'
import tableConfig from 'config/table.config'

const HomePage = (): JSX.Element => {
    const classes = styles()

    const navigate = useNavigate()
    const { createScan } = useScanAPI()
    const [filters, setFilters] = useState({
        date: new Date(),
    })

    const {
        claims,
        loading: claimsLoading,
        error,
    } = useClaims({
        date: formatDateToDateString(filters.date) as never,
        ordering: '-updated_at',
    })
    const { scanners, loading: scannersLoading } = useScanners()

    const handleGoToClaim = (id: string) => {
        navigate(appPaths.claim(id))
    }

    const handleScan = (data: ScanOptions) => {
        createScan({
            ...(data.batch !== 'new' && { batchId: data.batch }),
            ...(data.claim !== 'new' && { claimId: data.claim }),
            scannerId: data.scanner,
            batchType: data.type,
            sourceName: data.source,
        }).then(response => {
            navigate(appPaths.claim(response?.claimId || 'error'))
        })
    }

    return (
        <PageLayout
            loading={scannersLoading}
            error={!!error}
            extraClasses={{ page: classes.page }}
        >
            <ScanToolbar scanners={scanners} onScan={handleScan} />
            <Divider className={classes.pageDivider} />
            <div className={classes.block}>
                <div className={classes.bodyHeader}>
                    <div className={classes.bodyActions}>
                        <Typography variant="h4">Claims</Typography>
                    </div>
                    <div className={classes.filters}>
                        <DateField
                            extraClasses={{ datepicker: classes.input }}
                            label="Date"
                            value={formatDateToDateString(filters.date)}
                            onChange={(date: string | null) => {
                                setFilters(prevState => ({
                                    ...prevState,
                                    date: new Date(date as string) || '',
                                }))
                            }}
                        />
                        <Button
                            variant="outlined"
                            className={classes.downloadButton}
                            startIcon={<Summarize />}
                            onClick={() =>
                                navigate(
                                    `${appPaths.report()}?date=${formatDateToDateString(
                                        filters.date
                                    )}`
                                )
                            }
                        >
                            Report
                        </Button>
                    </div>
                </div>
                <Divider />
                <div className={classes.content}>
                    <Table
                        loading={claimsLoading}
                        extraClasses={{ root: classes.table }}
                        onRowClick={params => handleGoToClaim(params.row.id)}
                        count={claims.length}
                        columns={tableConfig.claims.columns}
                        rows={claims}
                    />
                </div>
            </div>
        </PageLayout>
    )
}

export default HomePage
