import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    checkboxHelper: {
        marginTop: '4px',
    },
    alignHelperText: {
        marginLeft: '28px',
    },
}))
