import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
    ClaimDocument,
    ClaimListDocument,
    ClaimListQueryVariables,
    ClaimType,
} from 'api/generated'

export const useClaim = (id: string) => {
    const { data, loading, error, refetch } = useQuery(ClaimDocument, {
        variables: { id },
    })

    let claim

    if (data?.claim) {
        claim = {
            ...data.claim,
            scans: data.claim.scans.edges.map(e => e?.node),
        }
    }

    return {
        claim,
        loading,
        error,
        refetch,
    }
}

export const useClaims = (variables: ClaimListQueryVariables) => {
    const [items, setItems] = useState<ClaimType[]>([])
    const { data, loading, error, refetch } = useQuery(ClaimListDocument, {
        variables,
    })

    useEffect(() => {
        if (data?.claimList?.edges) {
            setItems(
                data.claimList.edges.map(
                    e => e?.node as ClaimType
                ) as ClaimType[]
            )
        }
    }, [data])

    return {
        claims: items,
        refetch,
        loading,
        error,
    }
}
