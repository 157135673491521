import styles from './index.styles'

import React from 'react'

import { CircularProgress } from '@mui/material'

const Loader: React.FC = () => {
    const classes = styles()

    return (
        <div className={classes.loader}>
            <CircularProgress />
        </div>
    )
}
export default Loader
