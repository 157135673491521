import { useMutation, FetchResult } from '@apollo/client'

import {
    EmailSignInDocument,
    EmailSignInMutation,
    MutationEmailSignInArgs,
} from 'api/generated'
import { client } from 'api'
import { TokenStorage } from 'services'
import { LOGOUT_PATH } from 'config/env.config'

const useAuth = () => {
    const [signInApi] = useMutation(EmailSignInDocument)

    const signIn = (data: MutationEmailSignInArgs) => {
        return signInApi({ variables: data }).then(
            (response: FetchResult<EmailSignInMutation>) => {
                const result = response?.data?.emailSignIn
                if (result?.success) {
                    const { token, refreshToken } = result
                    TokenStorage.storeToken(token as string)
                    TokenStorage.storeRefreshToken(refreshToken as string)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.location = window.location.origin
                    return client.resetStore().then(() => {
                        return result
                    })
                }
                return result
            }
        )
    }

    const logout = () => {
        TokenStorage.clear()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = window.location.href
    }

    const isAuthenticated = () => TokenStorage.isAuthenticated()

    return {
        signIn,
        logout,
        isAuthenticated,
    }
}

export default useAuth
