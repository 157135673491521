import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    page: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
}))
