import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    app: {
        backgroundColor: theme.colors.background,
        display: 'flex',
        height: '100vh',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
}))
