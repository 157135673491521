import styles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import useAuth from 'hooks/auth'
import logo from 'assets/images/logo.svg'
import { appPaths } from 'routes'

const Header: React.FC = () => {
    const classes = styles()

    const { logout } = useAuth()
    const navigate = useNavigate()

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.root}
        >
            <img
                role="presentation"
                src={logo}
                alt="asc logo"
                className={classes.logo}
                onClick={() => navigate(appPaths.home())}
            />
            <Logout onClick={logout} />
        </Stack>
    )
}

export default Header
