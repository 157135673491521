import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    root: {
        textAlign: 'left',
        backgroundColor: 'transparent',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    caption: {
        fontSize: '12px',
        color: '#b3b5b6',
    },
}))
