import styles from './index.styles'

import { FC } from 'react'

import { Stack } from '@mui/material'

interface CellWithButtonsProps {
    buttons: JSX.Element[]
}

const CellWithButtons: FC<CellWithButtonsProps> = ({
    buttons,
}: CellWithButtonsProps) => {
    const classes = styles()
    return (
        <Stack direction="row" spacing={1} className={classes.root}>
            {buttons}
        </Stack>
    )
}

export default CellWithButtons
