import { makeStyles } from '@mui/styles'

export default makeStyles({
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        padding: '20px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 999,
        backgroundColor: 'rgba(255,255,255,0.4)',
    },
})
