import styles from './index.styles'

import React from 'react'

import { Box, CircularProgress } from '@mui/material'

const CellWithLoader = () => {
    const classes = styles()

    return (
        <Box className={classes.root}>
            <CircularProgress size={20} />
        </Box>
    )
}

export default CellWithLoader
