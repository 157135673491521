import styles from './index.styles'

import { useEffect, useState } from 'react'

import cx from 'classnames'
import { Stack, Box, LinearProgress } from '@mui/material'
import {
    DataGrid,
    GridColDef,
    GridOverlay,
    GridValidRowModel,
    GridCallbackDetails,
    GridEventListener,
    GridSortItem,
} from '@mui/x-data-grid'

import { CellWithButtons } from './cells'

export interface TableProps {
    columns: GridColDef[]
    loading?: boolean
    checkboxSelection?: boolean
    count: number
    itemsPerPage?: number
    renderActions?: (params: any) => JSX.Element[]
    rows: GridValidRowModel[]
    onPageChange?: (page: number, details: GridCallbackDetails) => void
    onRowClick?: GridEventListener<'rowClick'>
    onSelectionModelChange?: (selected: any) => void
    extraClasses?: { [key: string]: string }
}

const Table = ({
    columns,
    rows,
    loading = false,
    checkboxSelection = false,
    onSelectionModelChange,
    renderActions,
    count,
    onPageChange,
    itemsPerPage = 10,
    onRowClick,
    extraClasses = {},
}: TableProps) => {
    const classes = styles()

    const [resultColumns, setResultColumns] = useState<GridColDef[]>(columns)

    useEffect(() => {
        if (renderActions) {
            setResultColumns([
                ...columns,
                {
                    field: 'actions',
                    headerName: 'Actions',
                    flex: 1,
                    renderCell: params => (
                        <CellWithButtons buttons={renderActions(params)} />
                    ),
                },
            ])
        }
    }, [columns, renderActions])

    const renderLoader = () => {
        return (
            <GridOverlay>
                <Box className={classes.loaderContainer}>
                    <LinearProgress />
                </Box>
            </GridOverlay>
        )
    }

    const renderEmptyTable = () => {
        return (
            <GridOverlay>
                <Box className={classes.emptyMessage}>
                    There are no claims for selected date
                </Box>
            </GridOverlay>
        )
    }

    return (
        <Stack className={cx(classes.root, extraClasses?.root)}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [
                            {
                                field: 'createdAt',
                                sort: 'asc',
                            },
                        ],
                    },
                }}
                checkboxSelection={checkboxSelection}
                onSelectionModelChange={onSelectionModelChange}
                onRowClick={onRowClick}
                pageSize={itemsPerPage}
                rowCount={count}
                loading={loading}
                rows={rows}
                columns={resultColumns}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                autoHeight
                onPageChange={onPageChange}
                rowsPerPageOptions={[10]}
                paginationMode="client"
                components={{
                    LoadingOverlay: renderLoader,
                    NoRowsOverlay: renderEmptyTable,
                }}
            />
        </Stack>
    )
}

export default Table
