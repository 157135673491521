import styles from './index.styles'

import React from 'react'

import { PageLayout } from 'components/layout'

const NotFoundPage = (): JSX.Element => {
    const classes = styles()
    return (
        <PageLayout title="Not found" extraClasses={{ page: classes.page }} />
    )
}

export default NotFoundPage
