import { GridAlignment, GridColDef } from '@mui/x-data-grid'

import {
    CellWithLoader,
    CellWithBatchType,
} from 'components/common/Table/cells'
import { BATCH_TYPES } from 'utils/types.utils'
import { formatDateToDateString } from '../utils/date.utils'

export default {
    claims: {
        name: 'claims',
        // query: AppointmentListDocument,
        path: 'claims',
        itemsPerPage: 10,
        columns: [
            {
                field: 'batch',
                headerName: 'Batch name',
                sortable: false,
                flex: 2,
                renderCell: (params: any) => params.row.batch.name,
            },
            {
                field: 'type',
                headerName: 'Batch type',
                sortable: false,
                flex: 2,
                renderCell: (params: any) => (
                    <CellWithBatchType type={params.row.batch.type} />
                ),
            },
            {
                field: 'name',
                headerName: 'Claim',
                sortable: false,
                flex: 2,
            },
            {
                field: 'updatedAt',
                headerName: 'Updated at',
                sortable: false,
                flex: 1,
                renderCell: (params: any) =>
                    formatDateToDateString(
                        new Date(params.value),
                        'y-MM-dd, hh:mm'
                    ),
            },
            {
                field: 'scans',
                headerName: 'Scans',
                sortable: false,
                align: 'center' as GridAlignment,
                renderCell: (params: any) =>
                    params.row.status?.val === 'Scanning' ? (
                        <CellWithLoader />
                    ) : (
                        params.value.edges.length || '-'
                    ),
            },
        ] as GridColDef[],
    },
}
