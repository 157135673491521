import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { TokenStorage } from 'services'
import { client } from 'api'
import { appTheme } from 'theme'
import App from 'components/app'
import { Loader } from 'components/layout'
import Auth from 'components/auth'
import LoginPage from './components/pages/LoginPage'
import NotFoundPage from './components/pages/NotFoundPage'

declare global {
    interface Window {
        COMMIT_TAG: string
    }
}

ReactDOM.render(
    <ThemeProvider theme={appTheme}>
        <ApolloProvider client={client}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={5000}
                    variant="info"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <BrowserRouter>
                            {TokenStorage.isAuthenticated() ? (
                                <Routes>
                                    <Route path="/*" element={<App />} />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route path="/" element={<LoginPage />} />
                                    <Route
                                        path="*"
                                        element={<NotFoundPage />}
                                    />
                                </Routes>
                            )}
                        </BrowserRouter>
                    </StyledEngineProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ApolloProvider>
    </ThemeProvider>,
    document.getElementById('root')
)
