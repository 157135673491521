import React, { FC } from 'react'

import { Navigate } from 'react-router-dom'

import { authPaths } from 'routes'
import { TokenStorage } from 'services'

export const withAuthorizationGuard = (Component: FC): (() => JSX.Element) =>
    function WrappedComponent() {
        return TokenStorage.isAuthenticated() ? (
            <Component />
        ) : (
            <Navigate to={{ pathname: authPaths.login }} />
        )
    }

// import React from 'react'
//
// import { TokenStorage } from 'services'
// import { PUBLIC_AUTH } from '../config/env.config'
//
// export const withAuth = (Component: React.FC): (() => JSX.Element | null) => {
//     return function WrappedComponent() {
//         const params = new URLSearchParams(window.location.search)
//
//         const token = params.get('token')
//         const refreshToken = params.get('refreshToken')
//
//         if (TokenStorage.isAuthenticated()) {
//             return <Component />
//         }
//
//         if (token && refreshToken) {
//             TokenStorage.storeToken(token)
//             TokenStorage.storeRefreshToken(refreshToken)
//             return <Component />
//         }
//         window.location = PUBLIC_AUTH
//         return null
//     }
// }
