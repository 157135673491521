import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    root: {
        flexGrow: 0,
        flexDirection: 'row',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: '16px',
    },
    title: {},
    content: {
        flexGrow: 1,
    },
}))
