import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        color: theme.colors.neutral,
        marginRight: '8px',
    },
    text: {},
}))
