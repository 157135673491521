import styles from './index.styles'

import React, { useEffect, useState } from 'react'

import { Button, Stack, Switch, Typography } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import classNames from 'classnames'

import { SelectField } from '../../inputs'
import { useClaims } from 'hooks/claims'
import { ScannerType } from 'api/generated'
import { BATCH_TYPES } from 'utils/types.utils'
import { formatDateToDateString } from 'utils/date.utils'
import { useBatches } from 'hooks/batches'

export interface ScanOptions {
    scanner: string
    claim: string
    batch: string
    type: BATCH_TYPES
    source: string
}

interface ScanToolbarProps {
    scanners: ScannerType[]
    onScan: (data: ScanOptions) => void
}

const ScanToolbar = ({ scanners, onScan }: ScanToolbarProps) => {
    const classes = styles()

    const [sources, setSources] = useState<string[]>([])

    const [options, setOptions] = useState<ScanOptions>({
        scanner: '',
        batch: 'new',
        claim: 'new',
        type: BATCH_TYPES.PREDET,
        source: '',
    })

    const { batches, refetch: refetchBatches } = useBatches({
        date: formatDateToDateString(new Date()) as never,
        type: options.type as never,
    })

    useEffect(() => {
        refetchBatches()
    }, [options.type, refetchBatches])

    const { claims, refetch: refetchClaims } = useClaims({
        date: formatDateToDateString(new Date()) as never,
        ...(options.batch !== 'new' && { batchId: options.batch }),
    })

    useEffect(() => {
        refetchClaims()
    }, [options.batch, refetchClaims])

    useEffect(() => {
        if (options.scanner) {
            const selectedScanner = scanners.find(s => s.id === options.scanner)
            const scannerSources = selectedScanner?.sources as string[]
            setSources(scannerSources || [])
            setOptions(prevState => ({
                ...prevState,
                source: scannerSources[0],
            }))
        }
    }, [options.scanner])

    const handleScan = () => {
        onScan(options)
    }

    const renderBatchesItems = () => {
        return [
            {
                name: 'New Batch',
                id: 'new',
            },
            ...batches.filter(b => b.type?.key === options.type),
        ].map(batch => ({
            label: batch.name as string,
            value: batch.id,
            key: batch.id,
        }))
    }

    const renderClaimsItems = () => {
        return [
            {
                name: 'New Claim',
                id: 'new',
            },
            ...claims,
        ].map(s => ({
            label: s.name as string,
            value: s.id,
            key: s.id,
        }))
    }

    return (
        <div className={classes.root}>
            <div className={classes.fields}>
                <Stack direction="row" alignItems="center" gap="8px">
                    <Typography
                        className={classNames(classes.switchLabel, {
                            [classes.active]:
                                options.type === BATCH_TYPES.PREDET,
                        })}
                    >
                        PreDET
                    </Typography>
                    <Switch
                        className={classes.switch}
                        color="secondary"
                        onChange={event => {
                            setOptions(prevState => ({
                                ...prevState,
                                type: event.target.checked
                                    ? BATCH_TYPES.REGULAR
                                    : BATCH_TYPES.PREDET,
                            }))
                        }}
                    />

                    <Typography
                        className={classNames(classes.switchLabel, {
                            [classes.active]:
                                options.type === BATCH_TYPES.REGULAR,
                        })}
                    >
                        Regular
                    </Typography>
                </Stack>
                <SelectField
                    id="batch"
                    name="batch"
                    label="Batch"
                    extraClasses={{ root: classes.input }}
                    value={options.batch}
                    onChange={event =>
                        setOptions(prevState => ({
                            ...prevState,
                            batch: event.target.value,
                        }))
                    }
                    options={renderBatchesItems()}
                />
                <SelectField
                    id="claim"
                    name="claim"
                    label="Choose claim"
                    extraClasses={{ root: classes.input }}
                    value={options.claim}
                    disabled={options.batch === 'new'}
                    onChange={event =>
                        setOptions(prevState => ({
                            ...prevState,
                            claim: event.target.value,
                        }))
                    }
                    options={renderClaimsItems()}
                />
                <SelectField
                    id="scanner"
                    name="scanner"
                    label="Choose scanner"
                    extraClasses={{ root: classes.input }}
                    value={options.scanner}
                    onChange={event =>
                        setOptions(prevState => ({
                            ...prevState,
                            scanner: event.target.value,
                        }))
                    }
                    options={scanners.map(s => ({
                        label: `${s.model} (${s.type}), ${s.vendor}`,
                        value: s.id as string,
                        key: s.id as string,
                    }))}
                />
                <SelectField
                    id="source"
                    name="source"
                    label="Choose source"
                    disabled={!options.scanner || sources.length === 1}
                    extraClasses={{ root: classes.input }}
                    value={options.source}
                    onChange={event =>
                        setOptions(prevState => ({
                            ...prevState,
                            source: event.target.value,
                        }))
                    }
                    options={sources.map(s => ({
                        label: s as string,
                        value: s as string,
                        key: s as string,
                    }))}
                />
            </div>
            <div className={classes.actions}>
                <Button
                    className={classes.createButton}
                    color="secondary"
                    variant="outlined"
                    endIcon={<ArrowForwardIos />}
                    onClick={handleScan}
                    disabled={!options.scanner}
                >
                    Scan
                </Button>
            </div>
        </div>
    )
}

export default ScanToolbar
