export default class TokenStorage {
    static LOCAL_STORAGE_TOKEN = 'token'

    static LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token'

    static isAuthenticated(): boolean {
        return this.getToken() !== null
    }

    static getAuthenticationHeader(): string {
        return `JWT ${this.getToken()}`
    }

    static storeToken(token: string): void {
        return localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token)
    }

    static storeRefreshToken(refreshToken: string): void {
        localStorage.setItem(
            TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
            refreshToken
        )
    }

    static clear(): void {
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN)
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN)
    }

    static getRefreshToken(): string {
        return localStorage.getItem(
            TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN
        ) as string
    }

    static getToken(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN)
    }
}
