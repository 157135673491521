import styles from './index.styles'

import React from 'react'

import { Warning } from '@mui/icons-material'

const GlobalError: React.FC = () => {
    const classes = styles()

    return (
        <div className={classes.error}>
            <Warning className={classes.icon} />
            <span className={classes.text}>Something went wrong</span>
        </div>
    )
}
export default GlobalError
