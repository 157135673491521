import styles from './index.styles'

import React from 'react'

import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

import DialogButtons from './Buttons'

export interface DialogProps {
    title: string
    onClose: () => void
    onSubmit?: () => void
    children?: JSX.Element
}

const Dialog = (props: DialogProps): JSX.Element => {
    const classes = styles()
    const { children, title, onSubmit, onClose } = props
    return (
        <MuiDialog
            maxWidth={false}
            open
            onBackdropClick={onClose}
            onClose={onClose}
        >
            {title && (
                <DialogTitle className={classes.title}>
                    <Stack className={classes.titleText}>
                        <span>{title}</span>
                    </Stack>
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            {onSubmit && (
                <DialogButtons
                    removePaddings={false}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                />
            )}
        </MuiDialog>
    )
}

export default Dialog
