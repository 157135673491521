import styles from './index.styles'

import React from 'react'

import cx from 'classnames'
import { TextField as MaterialTextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

interface SelectOption {
    key: string
    label: string
    value: number | string
    caption?: string | null
}

interface SelectFieldProps {
    id: string
    name: string
    label: string
    value: number | string
    size?: 'small' | 'medium'
    variant?: 'standard' | 'filled' | 'outlined'
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    error?: boolean | undefined
    helperText?: string | false | undefined
    options: SelectOption[]
    disabled?: boolean
    extraClasses?: { [key: string]: string }
}

const SelectField = ({
    options,
    extraClasses = {},
    ...rest
}: SelectFieldProps): JSX.Element => {
    const classes = styles()

    return (
        <MaterialTextField
            FormHelperTextProps={{
                variant: 'standard',
            }}
            select
            variant="outlined"
            autoComplete="off"
            fullWidth
            className={cx(classes.root, extraClasses?.root)}
            {...rest}
        >
            {options.map(option => (
                <MenuItem
                    key={option.key}
                    value={option.value}
                    className={classes.item}
                >
                    {option.label}
                </MenuItem>
            ))}
        </MaterialTextField>
    )
}

export default SelectField
