import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    root: {
        width: '100%',
        '& .MuiDataGrid-root': {
            border: 'none',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
            fontSize: '20px',
            fontWeight: '500',
            color: 'rgba(0, 0, 0, .87)',
        },
    },
    emptyMessage: {},
    errorMessage: {},
    loaderContainer: { position: 'absolute', top: 0, width: '100%' },
}))
