import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        background: theme.colors.primaryLight,
        padding: '15px 25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '40px',
        boxShadow: '1px 1px 1px #ccc',
    },
    fields: {
        display: 'flex',
        gap: '25px',
        flexBasis: '75%',
    },
    actions: {
        flexBasis: '25%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    createButton: {
        padding: '15px',
    },
    input: {
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        },
        '& .MuiOutlinedInput-root': {
            color: '#eaeaea',
        },
        '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                borderWidth: '1px',
            },
        },
        '& .Mui-disabled': {
            '&.MuiSelect-select': {
                '-webkit-text-fill-color': '#a7a4a4 !important',
            },
            '& .MuiSelect-icon': {
                color: '#a7a4a4',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#a7a4a4',
                color: 'red',
            },
            '& .MuiOutlinedInput-root': {
                color: '#a7a4a4',
            },
            '&.MuiInputLabel-root': {
                color: '#a7a4a4',
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#a7a4a4',
                },
            },
        },
        '& .MuiInputLabel-root': {
            color: '#eaeaea',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors.neutral75,
        },
        '& .MuiSelect-icon': {
            color: '#dad9d9',
        },
    },
    switch: {
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: '0.38',
        },
    },
    switchLabel: {
        color: theme.colors.neutral75,
    },
    active: {
        color: theme.colors.secondary,
    },
}))
