import styles from './index.styles'

import React, { ReactNode } from 'react'

import { Box } from '@mui/material'
import cx from 'classnames'

import { GlobalError, Loader } from 'components/layout'
import { PageTitle } from 'components/common'
import { useDocumentTitle } from 'hooks/common'

interface PageLayoutProps {
    error?: boolean
    loading?: boolean
    extraClasses?: { [key: string]: string }
    title?: string
    hideHeader?: boolean
    children?: JSX.Element | JSX.Element[] | ReactNode
    actions?: JSX.Element[] | JSX.Element
}

const PageLayout: React.FC<PageLayoutProps> = ({
    loading = false,
    error = false,
    title,
    extraClasses,
    children,
    actions,
    hideHeader = false,
}: PageLayoutProps) => {
    const classes = styles()

    useDocumentTitle(title)

    if (error) {
        return <GlobalError />
    }

    return (
        <div className={cx(classes.page, extraClasses?.page)}>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {(title || actions) && !hideHeader && (
                        <Box
                            className={cx(classes.header, extraClasses?.header)}
                        >
                            {title && (
                                <Box className={classes.titleContainer}>
                                    <PageTitle title={title} />
                                </Box>
                            )}
                            {actions && (
                                <Box className={classes.actions}>{actions}</Box>
                            )}
                        </Box>
                    )}
                    {children && (
                        <Box
                            className={cx(
                                classes.content,
                                extraClasses?.content
                            )}
                        >
                            {children}
                        </Box>
                    )}
                </>
            )}
        </div>
    )
}

export default PageLayout
