import styles from './index.styles'

import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import {
    Button,
    Divider,
    Stack,
    Box,
    IconButton,
    Typography,
} from '@mui/material'
import {
    KeyboardDoubleArrowDown,
    Delete,
    Image,
    FactCheck,
    ArrowBackIos,
    ArrowForwardIos,
} from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSubscription } from '@apollo/client'

import { PageLayout, Loader } from 'components/layout'
import { useClaim } from 'hooks/claims'
import { useScanAPI } from 'hooks/scans'
import { TitledText, SelectScannerButton } from 'components/common'
import { ScannerType, ScanType, OnNewScanCreatedDocument } from 'api/generated'
import { API_ROOT } from 'config/env.config'
import { appPaths } from 'routes'
import { useScanners } from 'hooks/config'

const ClaimPage = (): JSX.Element => {
    const classes = styles()

    const { data } = useSubscription(OnNewScanCreatedDocument)

    const { id } = useParams()
    const scrollableRef = useRef<HTMLDivElement>(null)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const { scanners } = useScanners()
    const { claim, loading, error, refetch } = useClaim(id as string)
    const { deleteScan, createScan } = useScanAPI()
    const [activeImage, setActiveImage] = useState<string | null>(null)
    const [isLoading, setLoading] = useState(true)
    const [isScanningMore, setScanningMore] = useState(false)

    useEffect(() => {
        if (claim) {
            if (claim?.status?.key === 2) {
                setLoading(false)
            }
        }
    }, [claim])

    useEffect(() => {
        if (data?.onNewScanCreated?.error) {
            enqueueSnackbar(data?.onNewScanCreated?.error as string)
        } else if (claim?.status?.key === 1) {
            if (!data?.onNewScanCreated?.inProgress) {
                setLoading(false)
                setScanningMore(false)
            }
        }
    }, [data])

    const removeScan = (e: SyntheticEvent, scanId: string) => {
        e.stopPropagation()
        deleteScan(scanId).then(isSuccess => {
            if (isSuccess) {
                if (activeImage === scanId) {
                    setActiveImage(null)
                }
                refetch().then(() => enqueueSnackbar('Deleted successfully'))
            }
        })
    }

    const renderLoadingState = () => {
        return (
            <Stack>
                <Box className={classes.loaderContainer}>
                    <Loader />
                </Box>
                <Typography className={classes.scanningMessage}>
                    Scanning
                </Typography>
            </Stack>
        )
    }

    const renderEmptyImageView = () => {
        return isLoading ? (
            renderLoadingState()
        ) : (
            <Stack className={classes.emptyView} alignItems="center">
                <FactCheck />
                <Typography
                    variant="h5"
                    color="neutral"
                    className={classes.emptyViewText}
                >
                    Select an image from the list and it will be shown here
                </Typography>
            </Stack>
        )
    }

    const composeMediaPath = (path: string) => {
        const root =
            process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_MEDIA_ROOT
                : API_ROOT.replace('/graphql/', '')

        return `${root}${path}`
    }

    const renderImage = (imageId: string) => {
        if (imageId) {
            const image = claim?.scans.find(i => i?.id === imageId)

            if (image) {
                return (
                    <Box className={classes.imageContainer}>
                        <img
                            className={classes.previewImage}
                            src={composeMediaPath(image.previewUrl as string)}
                            alt="full-document"
                        />
                    </Box>
                )
            }
            return null
        }

        return null
    }

    const renderPreviews = (scans: ScanType[]) => {
        return scans.map((scan, index) => (
            <Box
                key={scan.id}
                className={classNames(classes.preview, {
                    [classes.activeImage]: scan.id === activeImage,
                })}
                onClick={() => setActiveImage(scan.id || null)}
            >
                <img
                    src={composeMediaPath(scan.previewUrl as string)}
                    alt="preview"
                    className={classes.previewImage}
                />
                <IconButton
                    className={classes.removeButton}
                    color="error"
                    size="large"
                    onClick={(e: SyntheticEvent) => removeScan(e, scan.id)}
                >
                    <Delete />
                </IconButton>
                <Typography className={classes.pageNumber} variant="subtitle2">
                    {index + 1}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="5px"
                    className={classes.caption}
                >
                    <Image />
                    <Typography variant="body2">{scan.filename}</Typography>
                </Stack>
            </Box>
        ))
    }

    const handleScan = (scanner: ScannerType, source: string) => {
        createScan({
            batchId: claim?.batch.id,
            claimId: claim?.id,
            batchType: claim?.batch.type?.key,
            scannerId: scanner.id as string,
            sourceName: source,
        })
            .then(() => enqueueSnackbar('Scanning started'))
            .then(() => setScanningMore(true))
    }

    return (
        <PageLayout
            extraClasses={{ page: classes.page }}
            loading={loading}
            error={!!error}
        >
            <Stack
                direction="row"
                alignItems="center"
                className={classes.header}
            >
                <IconButton onClick={() => navigate(appPaths.home())}>
                    <ArrowBackIos />
                </IconButton>
                <Divider orientation="vertical" className={classes.divider} />
                <Stack direction="row" gap="30px" flexBasis="40%">
                    <TitledText title="Batch:" variant="body1">
                        {claim?.batch.name}
                    </TitledText>
                    <TitledText title="Batch type:" variant="body1">
                        {claim?.batch?.type?.val}
                    </TitledText>
                    <TitledText title="Claim:" variant="body1">
                        {claim?.name}
                    </TitledText>
                </Stack>
                <Stack
                    direction="row"
                    flexBasis="60%"
                    className={classes.actions}
                >
                    <SelectScannerButton
                        disabled={isLoading}
                        loading={isScanningMore}
                        scanners={scanners}
                        onSelect={(scanner, source) => {
                            handleScan(scanner, source)
                        }}
                    />
                </Stack>
            </Stack>
            <Stack
                className={classes.body}
                direction="row"
                alignItems="stretch"
            >
                <Box
                    className={classes.content}
                    justifyContent="center"
                    alignItems="center"
                >
                    {activeImage
                        ? renderImage(activeImage as string)
                        : renderEmptyImageView()}
                </Box>
                <Stack className={classes.sidebar}>
                    <Box className={classes.sidebarHeader}>
                        <Typography variant="h5">Documents</Typography>
                    </Box>
                    <Stack
                        gap="15px"
                        className={classes.scrollableList}
                        ref={scrollableRef}
                    >
                        {renderPreviews((claim?.scans || []) as ScanType[])}
                    </Stack>
                    {claim?.scans?.length && claim?.scans?.length > 2 && (
                        <Box
                            className={classes.sidebarFooter}
                            onClick={() =>
                                scrollableRef?.current?.scrollTo({
                                    top: scrollableRef?.current.scrollHeight,
                                    behavior: 'smooth',
                                })
                            }
                        >
                            <Typography variant="h5">
                                <KeyboardDoubleArrowDown />
                            </Typography>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </PageLayout>
    )
}

export default ClaimPage
