import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: '1',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '30px',
    },
    title: {},
    content: {
        position: 'relative',
        flex: '1',
    },
    actions: {
        cursor: 'pointer',
    },
}))
