import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px 0',
        border: `1px dashed ${theme.colors.neutral}`,
        cursor: 'pointer',

        borderRadius: '3px',
        '&:hover': {
            borderColor: theme.colors.primary,
        },
    },
    disabled: {
        opacity: 0.5,
    },
    title: {
        color: theme.colors.neutral,
        marginBottom: '10px',
    },
    caption: {
        color: theme.colors.neutral,
    },
    link: {
        color: theme.colors.primary,
    },
}))
