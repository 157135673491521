import styles from './index.styles'

import React, { FC, PropsWithChildren } from 'react'

import { Box, Typography, TypographyVariant } from '@mui/material'
import cx from 'classnames'

export interface TitledTextProps {
    title: string
    extraClasses?: { [key: string]: string }
    icon?: JSX.Element
    variant?: TypographyVariant
}

const TitledText: FC<PropsWithChildren<TitledTextProps>> = ({
    title,
    children,
    extraClasses,
    icon,
    variant,
}: PropsWithChildren<TitledTextProps>) => {
    const classes = styles()

    return (
        <Box className={cx(classes.root, extraClasses?.root)}>
            {icon}
            <Typography
                variant={variant}
                className={cx(classes.title, extraClasses?.title)}
            >
                {title}
            </Typography>
            <Typography
                variant={variant}
                className={cx(classes.text, extraClasses?.text)}
            >
                {children}
            </Typography>
        </Box>
    )
}

export default TitledText
