import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        background: theme.colors.primaryGradient,
        padding: '5px 40px',
        boxShadow: '1px 1px 1px #ccc',
        marginBottom: '25px',
    },
    logo: {
        width: '120px',
        cursor: 'pointer',
    },
}))
