import styles from './index.styles'

import React, { useMemo } from 'react'

import cx from 'classnames'
import { format } from 'date-fns'
import { Box } from '@mui/material'
import { DatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers'

import { TextField } from 'components/inputs'

interface DatepickerProps {
    onChange: (date: string | null) => void
    fullWidth?: boolean
    error?: boolean | undefined
    helperText?: string | false | undefined
    value: string | null | undefined
    dateFormat?: string
    daysEnabled?: Array<string | null> | null
    extraClasses?: { [key: string]: string }
    [key: string]: any
}

const Datepicker = ({
    onChange,
    fullWidth,
    error,
    helperText,
    value,
    dateFormat = 'y-MM-dd',
    daysEnabled = null,
    extraClasses = {},
    ...rest
}: DatepickerProps) => {
    const classes = styles()

    const renderPickedDays = (
        date: Date,
        selectedDate: Date[],
        params: PickersDayProps<Date>
    ) => {
        const props = { ...params }
        if (daysEnabled) {
            const isDayEnabled = !!daysEnabled.find(
                i => i === format(new Date(date || ''), 'yyyy-MM-dd')
            )
            props.disabled = !isDayEnabled
        }
        return <PickersDay {...props} />
    }

    const formattedValue = useMemo(() => {
        if (value === undefined) {
            return null
        }
        if (value === null) {
            return new Date('null')
        }
        if (value) {
            return new Date(new Date(value).setHours(0, 0, 0, 0))
        }
        return ''
    }, [value])

    const handleChangeValue = (date: Date | null) => {
        if (date?.toString() === 'Invalid Date' || !date) {
            onChange(null)
        } else {
            onChange(format(new Date(date || ''), dateFormat))
        }
    }

    return (
        <Box className={cx(classes.root, extraClasses?.root)}>
            <DatePicker
                renderDay={renderPickedDays}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                TextFieldProps={{
                    fullWidth: true,
                    className: cx(classes.datepicker, extraClasses?.datepicker),
                }}
                inputFormat="y-MM-dd"
                value={formattedValue}
                onChange={handleChangeValue}
                renderInput={params => (
                    <TextField
                        {...params}
                        error={error}
                        helperText={helperText}
                        fullWidth={fullWidth}
                    />
                )}
                {...rest}
            />
        </Box>
    )
}

export default Datepicker
