import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        borderRadius: '16px',
        padding: '6px 12px',
        textAlign: 'center',
        '&.success': {
            backgroundColor: theme.colors.success,
        },
        '&.normal': {
            backgroundColor: theme.colors.neutral50,
        },
        '&.warning': {
            backgroundColor: theme.colors.warning,
        },
        '&.error': {
            backgroundColor: theme.colors.error,
        },
    },
    title: {
        fontSize: '14px',
    },
}))
