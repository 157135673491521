import styles from './app.styles'

import React from 'react'

import { Stack } from '@mui/material'
import { useRoutes } from 'react-router-dom'

// import { withAuth } from 'hocs'
import { ReactiveDialog } from 'components/common'
import { Header } from 'components/layout'
import { appRoutes } from 'routes'
import { useVersionStamp } from 'hooks/common'
import { withAuthorizationGuard } from '../hocs'

const App = () => {
    const classes = styles()

    const routes = useRoutes(appRoutes)

    useVersionStamp()

    return (
        <div className={classes.app}>
            <ReactiveDialog />
            <Stack className={classes.content}>
                <Header />
                {routes}
            </Stack>
        </div>
    )
}

export default withAuthorizationGuard(App)
