import { makeStyles } from '@mui/styles'

export default makeStyles({
    content: {
        display: 'flex',
        overflow: 'hidden',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5px',
    },
    titleText: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
})
