import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        '& .Mui-selected': {
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },
        },
    },
    tabs: {
        maxWidth: '400px',
        marginBottom: '8px',
    },
    content: {},
}))
