import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    page: {
        display: 'flex',
        width: '100%',
        height: '80vh',
    },

    // HEADER
    header: {
        padding: '10px 0',
        marginBottom: '15px',
    },
    nameInput: {
        maxWidth: '250px',
        fontSize: '34px',
        '& .MuiInputBase-root': {
            border: 'none',
        },
    },
    divider: {
        margin: '0 20px 0 10px',
    },

    // BODY
    body: {
        height: '100%',
        minHeight: '820px',
    },
    content: {
        display: 'flex',
        gap: '50px',
        flexBasis: '80%',
        height: '100%',
        border: `1px solid ${theme.colors.neutral50}`,
        padding: '25px 50px',
        position: 'relative',
    },
    sidebar: {
        backgroundColor: theme.colors.neutral,
        flexBasis: '20%',
        overflow: 'hidden',
    },
    sidebarHeader: {
        backgroundColor: 'rgba(0, 0, 0, .4)',
        color: '#fff',
        padding: '15px',
        textAlign: 'center',
        borderBottom: '1px solid #000',
    },
    scrollableList: {
        overflowY: 'scroll',
        padding: '25px',
        height: '100%',
    },
    sidebarFooter: {
        backgroundColor: 'rgba(0, 0, 0, .4)',
        textAlign: 'center',
        color: '#fff',
        padding: '5px',
        cursor: 'pointer',
    },
    imageContainer: {
        width: '770px',
        height: '100%',
        background: 'rgba(0, 0, 0, .6)',
        border: `2px solid #000`,
    },
    preview: {
        border: `2px solid ${theme.colors.neutral50}`,
        background: 'rgba(0, 0, 0, .6)',
        width: '100%',
        position: 'relative',
        transition: '.1s ease',
        cursor: 'pointer',
        minHeight: '300px',
        maxHeight: '330px',
        justifyContent: 'center',
        '&:hover': {
            border: `2px solid ${theme.colors.primary}`,
            '& $removeButton': {
                display: 'flex',
            },
        },
    },
    activeImage: {
        border: `2px solid ${theme.colors.primaryLight}`,
        '& $caption': {
            backgroundColor: theme.colors.primaryLight,
        },
        '& $pageNumber': {
            backgroundColor: theme.colors.primaryLight,
        },
    },
    previewImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        textAlign: 'center',
    },
    pageNumber: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'white',
        padding: '4px 8px',
        background: 'rgba(0, 0, 0, .6)',
        borderRadius: '4px',
    },
    caption: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: '8px',
        background: 'rgba(0, 0, 0, .6)',
        color: 'white',
        textAlign: 'right',
    },
    removeButton: {
        background: 'white',
        padding: '10px',
        position: 'absolute',
        top: '10px',
        left: '10px',
        display: 'none',
        transition: '.1s ease',
        '&:hover': {
            background: 'white',
        },
    },
    emptyView: {
        color: theme.colors.neutral50,
        '& .MuiSvgIcon-root': {
            fontSize: '70px',
            marginBottom: '25px',
        },
    },
    loaderContainer: {
        position: 'relative',
        marginBottom: '30px',
    },
    scanningMessage: {
        fontSize: '22px',
        color: theme.colors.primary,
    },
    emptyViewText: {},
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    scanButton: {
        padding: '15px',
    },
    input: {
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        },
        '& .MuiOutlinedInput-root': {
            color: '#eaeaea',
        },
        '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                borderWidth: '1px',
            },
        },
        '& .MuiInputLabel-root': {
            color: '#eaeaea',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors.neutral75,
        },
        '& .MuiSelect-icon': {
            color: '#dad9d9',
        },
    },
}))
