import styles from './index.styles'

import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputBase,
    InputLabel,
    Stack,
    Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import useAuth from 'hooks/auth'
import { PasswordField, TextField } from 'components/inputs'
import logo from 'assets/images/logo.svg'

const Form = styled('form')``
const Image = styled('img')``

const LoginPage = (): JSX.Element => {
    const { signIn } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnChange: true,
        onSubmit: (values: any) => {
            signIn(values).catch(e => {
                formik.setErrors({
                    email: e.message,
                })
            })
        },
    })

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            height="100vh"
            sx={theme => ({
                backgroundColor: theme.colors.secondary,
            })}
        >
            <Form
                onSubmit={formik.handleSubmit}
                sx={theme => ({
                    padding: '15px 0 25px',
                    borderRadius: '12px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    boxShadow: '0px 2px 5px -2px #1b1b1b',
                })}
            >
                <Stack gap="24px">
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={theme => ({
                            backgroundColor: theme.colors.primary,
                            marginLeft: '-4px',
                            marginRight: '-4px',
                            boxShadow: '0px 3px 3px -2px #1b1b1b',
                        })}
                    >
                        <Image
                            src={logo}
                            sx={{
                                maxWidth: '150px',
                            }}
                        />
                    </Stack>
                    <Typography textAlign="center" fontSize="26px">
                        Sign in
                    </Typography>
                    <Stack gap="12px" padding="0 25px">
                        <TextField
                            autoFocus
                            id="email"
                            fullWidth
                            name="email"
                            placeholder="e.g. example@email.com"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                        />
                        <PasswordField
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            name="password"
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                            helperText={
                                formik.touched.password &&
                                formik.errors.password
                            }
                        />
                    </Stack>
                    <Stack gap="8px" padding="0 25px" minWidth="400px">
                        <Button
                            sx={theme => ({
                                padding: '11px 16px',
                                backgroundColor: theme.colors.primary,
                                fontSize: '15px',
                            })}
                            variant="contained"
                            type="submit"
                            fullWidth
                        >
                            LOG IN
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Stack>
    )
}

export default LoginPage
