import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    page: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.background,
        padding: '10px 40px',
    },
    header: {
        marginBottom: '40px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    titleContainer: {},
    actions: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginLeft: '15px',
        },
    },
}))
