import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { ReportDocument, PrintReportDocument } from '../api/generated'

export const useReport = (date?: string) => {
    const { data, loading, error } = useQuery(ReportDocument, {
        variables: { date },
    })

    let report

    if (data?.report) {
        report = data.report
    }

    return { report, loading, error }
}

export const useReportAPI = (date?: string) => {
    const [downloadReport, { data }] = useLazyQuery(PrintReportDocument, {
        variables: { date },
    })

    const download = () => downloadReport()

    return { download, data }
}
