import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    page: {
        display: 'flex',
        width: '100%',
        height: '83vh',
    },
    pageDivider: {
        margin: '25px auto',
        width: '100%',
    },

    // HEADER
    header: {
        padding: '10px 0',
    },
    downloadButton: {
        padding: '15px',
    },

    // BODY
    body: {
        height: '100%',
    },
    content: {
        display: 'flex',
        gap: '50px',
        flexBasis: '87%',
        height: '100%',
        border: `1px solid ${theme.colors.neutral50}`,
        padding: '50px',
    },
    block: {
        marginBottom: '20px',
    },
    blockHeader: {
        marginBottom: '15px',
    },
    table: {
        background: '#f5f5f5',
    },
}))
