import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

import { BATCH_TYPES } from 'utils/types.utils'

interface StyleProps {
    type: BATCH_TYPES
}

export default makeStyles<Theme, StyleProps>(() => ({
    root: {},
    dot: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: ({ type }) =>
            type === BATCH_TYPES.REGULAR ? '#ff0087' : '#0681fc',
    },
    title: {
        color: ({ type }) =>
            type === BATCH_TYPES.REGULAR ? '#ff0087' : '#0681fc',
    },
}))
