import styles from './index.styles'

import React, { useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    Button,
    Divider,
    Stack,
    Box,
    Typography,
    TableContainer,
    TableHead,
    TableBody,
    Table,
    TableCell,
    TableRow,
    Paper,
    IconButton,
} from '@mui/material'
import { Download, DateRange, ArrowBackIos } from '@mui/icons-material'

import { Loader, PageLayout } from 'components/layout'
import { DateModal } from 'components/common'
import { useReport, useReportAPI } from 'hooks/report'
import { useDialog } from 'hooks/common'
import { formatDateToDateString } from 'utils/date.utils'
import { appPaths } from 'routes'
import { TableReportType } from 'api/generated'

const ReportPage = (): JSX.Element => {
    const classes = styles()

    const [params] = useSearchParams()
    const date = params.get('date')
    const { open, close } = useDialog()
    const navigate = useNavigate()
    const { report, loading } = useReport(date as string)
    const { download, data } = useReportAPI(date as string)
    const handleOpenDateModal = () => {
        open({
            component: DateModal,
            props: {
                initialDate: date,
                onSave: (value: string | null) => {
                    close()
                    navigate(
                        `${appPaths.report()}?date=${formatDateToDateString(
                            new Date(value as string)
                        )}`
                    )
                },
            },
            options: {
                title: 'Change a date',
                onClose: close,
            },
        })
    }

    const renderRow = (
        value?: number | string | null,
        align: 'left' | 'center' = 'center'
    ) => (
        <TableCell component="th" scope="row" align={align}>
            {value}
        </TableCell>
    )

    const renderHeader = (
        name: string,
        align: 'left' | 'center' = 'center'
    ) => <TableCell align={align}>{name}</TableCell>

    const downloadFile = (filename: string, link?: string) => {
        if (link) {
            const element = document.createElement('a')
            element.setAttribute('download', filename)
            element.setAttribute('href', link)
            element.setAttribute('type', 'text/plain')

            element.style.display = 'none'

            document.body.appendChild(element)

            element.click()

            document.body.removeChild(element)
        }
    }

    useEffect(() => {
        if (data?.printReport) {
            downloadFile(`report-${date}.txt`, data?.printReport)
        }
    }, [data])

    const rows = (report?.table || []) as TableReportType[]

    return (
        <PageLayout extraClasses={{ page: classes.page }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.header}
            >
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => navigate(appPaths.home())}>
                        <ArrowBackIos />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <Typography variant="h4">{`Report for ${date}`}</Typography>
                    <IconButton onClick={handleOpenDateModal}>
                        <DateRange />
                    </IconButton>
                </Stack>
                <Button
                    variant="outlined"
                    startIcon={<Download />}
                    className={classes.downloadButton}
                    onClick={download}
                >
                    Download
                </Button>
            </Stack>
            <Divider className={classes.pageDivider} />
            {loading ? (
                <Loader />
            ) : (
                <Stack className={classes.body}>
                    <Box className={classes.block}>
                        <TableContainer
                            component={Paper}
                            className={classes.table}
                        >
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {renderHeader('Batch Type', 'left')}
                                        {renderHeader('Batch01')}
                                        {renderHeader('Batch02')}
                                        {renderHeader('Batch03')}
                                        {renderHeader('Batch04')}
                                        {renderHeader('Batch05')}
                                        {renderHeader('Batch06')}
                                        {renderHeader('Batch07')}
                                        {renderHeader('Batch08')}
                                        {renderHeader('Batch09')}
                                        {renderHeader('Batch10')}
                                        {renderHeader('Batch11')}
                                        {renderHeader('Batch12')}
                                        {renderHeader('Others')}
                                        {renderHeader('Claims')}
                                        {renderHeader('Pages')}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row: TableReportType) => (
                                        <TableRow
                                            key={row.batchType}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            {renderRow(row.batchType, 'left')}
                                            {renderRow(row.batch01)}
                                            {renderRow(row.batch02)}
                                            {renderRow(row.batch03)}
                                            {renderRow(row.batch04)}
                                            {renderRow(row.batch05)}
                                            {renderRow(row.batch06)}
                                            {renderRow(row.batch07)}
                                            {renderRow(row.batch08)}
                                            {renderRow(row.batch09)}
                                            {renderRow(row.batch10)}
                                            {renderRow(row.batch11)}
                                            {renderRow(row.batch12)}
                                            {renderRow(row.others)}
                                            {renderRow(row.claims)}
                                            {renderRow(row.pages)}
                                        </TableRow>
                                    ))}
                                    {/* {renderRows( */}
                                    {/*    (report?.table || */}
                                    {/*        []) as TableReportType[] */}
                                    {/* )} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Stack
                        className={classes.block}
                        direction="row"
                        justifyContent="flex-end"
                    >
                        <Typography variant="h5">
                            {`Grand totals: ${report?.grandTotalPages} pages,  ${report?.grandTotalClaims} claims`}
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </PageLayout>
    )
}

export default ReportPage
