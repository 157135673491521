import { makeStyles } from '@mui/styles'

export default makeStyles({
    container: {
        width: '100%',
        justifyContent: 'center',
    },
    contentCompensation: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
})
