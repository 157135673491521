import React from 'react'

import HomePage from 'components/pages/HomePage'
import ClaimPage from 'components/pages/ClaimPage'
import ReportPage from 'components/pages/ReportPage'
import NotFoundPage from 'components/pages/NotFoundPage'
import LoginPage from 'components/pages/LoginPage'

const appPaths = {
    home: () => '/',
    report: () => '/report',
    claim: (id: string) => `/claim/${id}`,
}

const authPaths = {
    login: '/auth/login',
}

const appRoutes = [
    {
        path: appPaths.home(),
        element: <HomePage />,
        name: 'home',
        index: true,
    },
    {
        path: appPaths.claim(':id'),
        element: <ClaimPage />,
        name: 'claim',
    },
    {
        path: appPaths.report(),
        element: <ReportPage />,
        name: 'report',
    },
    {
        path: `*`,
        element: <NotFoundPage />,
        name: '',
    },
]

const authRoutes = [
    {
        path: authPaths.login,
        element: <LoginPage />,
        name: 'login',
    },
    {
        path: `*`,
        element: <NotFoundPage />,
        name: '',
    },
]

export { appRoutes, appPaths, authRoutes, authPaths }
