import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
    BatchListDocument,
    BatchType,
    BatchListQueryVariables,
} from '../api/generated'

export const useBatches = (variables: BatchListQueryVariables) => {
    const [items, setItems] = useState<BatchType[]>([])
    const { data, loading, error, refetch } = useQuery(BatchListDocument, {
        variables,
    })

    useEffect(() => {
        if (data?.batchList?.edges) {
            setItems(
                data.batchList.edges.map(
                    e => e?.node as BatchType
                ) as BatchType[]
            )
        }
    }, [data])

    return {
        batches: items,
        loading,
        error,
        refetch,
    }
}
