import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    page: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
    },

    pageDivider: {
        margin: '30px auto',
        width: '100%',
    },

    input: {
        background: '#fff',
        minWidth: '180px',
    },

    // HEADER
    header: {
        background: theme.colors.primaryGradientReverted,
        border: `1px solid ${theme.colors.neutral50}`,
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '40px',
    },
    fields: {
        display: 'flex',
        gap: '25px',
        flexBasis: '50%',
    },
    actions: {
        flexBasis: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    createButton: {
        padding: '15px',
    },

    // BODY
    block: {
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        backgroundColor: '#f5f5f5',
        border: `1px solid ${theme.colors.neutral50}`,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiDataGrid-cell': {
            '&.regular': {
                color: '#ff0087',
            },
            '&.pre-det': {
                color: '#0681fc',
            },
        },
    },
    table: {
        minHeight: '629px',
    },
    filters: {
        display: 'flex',
        gap: '25px',
        flexBasis: '25%',
        minWidth: '400px',
    },
    bodyHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '40px',
    },
    bodyActions: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    downloadButton: {
        width: '100%',
    },
}))
