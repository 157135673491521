import React from 'react'

import type {} from '@mui/x-date-pickers/themeAugmentation'

import { createTheme } from '@mui/material/styles'

const background = '#fff'
const primaryGradient = 'linear-gradient(172deg, #00215c  40%, #79d3f7 100%)'
const primaryGradientReverted = 'linear-gradient(172deg, #79d3f7 40%, #00215c)'

const primary = {
    primary: '#00215c',
    primaryLight: '#3974a3',
}

const secondary = {
    secondary: 'rgba(242,247,241,1)',
}
const neutral = {
    neutral: '#5C5F61',
    neutral75: '#bec2c4',
    neutral50: '#abafb0',
}

const error = {
    error: '#BA1A1A',
}
const warning = {
    warning: '#FFBB00',
}
const success = {
    success: '#5C9F52',
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        tiny24: React.CSSProperties
        regular8: React.CSSProperties
        regular10: React.CSSProperties
        regular12: React.CSSProperties
        regular14: React.CSSProperties
        regular16: React.CSSProperties
        regular18: React.CSSProperties
        medium10: React.CSSProperties
        medium12: React.CSSProperties
        medium14: React.CSSProperties
        medium16: React.CSSProperties
        medium18: React.CSSProperties
        medium20: React.CSSProperties
        medium24: React.CSSProperties
        bold10: React.CSSProperties
        bold12: React.CSSProperties
        bold14: React.CSSProperties
        bold16: React.CSSProperties
        bold18: React.CSSProperties
        bold20: React.CSSProperties
        bold24: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        tiny24: React.CSSProperties
        regular8: React.CSSProperties
        regular10: React.CSSProperties
        regular12: React.CSSProperties
        regular14: React.CSSProperties
        regular16: React.CSSProperties
        regular18: React.CSSProperties
        medium10: React.CSSProperties
        medium12: React.CSSProperties
        medium14: React.CSSProperties
        medium16: React.CSSProperties
        medium18: React.CSSProperties
        medium20: React.CSSProperties
        medium24: React.CSSProperties
        bold10: React.CSSProperties
        bold12: React.CSSProperties
        bold14: React.CSSProperties
        bold16: React.CSSProperties
        bold18: React.CSSProperties
        bold20: React.CSSProperties
        bold24: React.CSSProperties
    }

    interface Theme {
        colors: {
            primary: React.CSSProperties['color']
            primaryLight: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            neutral: React.CSSProperties['color']
            neutral75: React.CSSProperties['color']
            neutral50: React.CSSProperties['color']
            error: React.CSSProperties['color']
            warning: React.CSSProperties['color']
            success: React.CSSProperties['color']
            background: React.CSSProperties['color']
            primaryGradient: React.CSSProperties['color']
            primaryGradientReverted: React.CSSProperties['color']
        }
    }

    interface ThemeOptions {
        colors: {
            primary: React.CSSProperties['color']
            primaryLight: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            neutral: React.CSSProperties['color']
            neutral75: React.CSSProperties['color']
            neutral50: React.CSSProperties['color']
            error: React.CSSProperties['color']
            warning: React.CSSProperties['color']
            success: React.CSSProperties['color']
            background: React.CSSProperties['color']
            primaryGradient: React.CSSProperties['color']
            primaryGradientReverted: React.CSSProperties['color']
        }
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        tiny24: true
        regular8: true
        regular10: true
        regular12: true
        regular14: true
        regular16: true
        regular18: true
        medium10: true
        medium12: true
        medium14: true
        medium16: true
        medium18: true
        medium20: true
        medium24: true
        bold10: true
        bold12: true
        bold14: true
        bold16: true
        bold18: true
        bold20: true
        bold24: true
    }
}

export const theme = createTheme({
    palette: {
        primary: { main: primary.primary, light: primary.primaryLight },
        secondary: { main: secondary.secondary },
        warning: { main: warning.warning },
        error: { main: error.error },
        success: { main: success.success },
    },
    colors: {
        ...primary,
        ...secondary,
        ...warning,
        ...error,
        ...success,
        ...neutral,
        background,
        primaryGradient,
        primaryGradientReverted,
    },
    breakpoints: {
        values: {
            xs: 432,
            sm: 834,
            md: 1340,
            lg: 1500,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Roboto',
        tiny24: {
            fontWeight: 300,
            fontSize: '24px',
            lineHeight: '32px',
        },
        regular8: {
            fontWeight: 400,
            fontSize: '8px',
            lineHeight: '10px',
        },
        regular10: {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '14px',
        },
        regular12: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
        },
        regular14: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
        regular16: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
        },
        regular18: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '24px',
        },
        medium10: {
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '14px',
        },
        medium12: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
        },
        medium14: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        medium16: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '22px',
        },
        medium18: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
        },
        medium20: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
        },
        medium24: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
        },
        bold10: {
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '14px',
        },
        bold12: {
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',
        },
        bold14: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '20px',
        },
        bold16: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '20px',
        },
        bold18: {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '24px',
        },
        bold20: {
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '28px',
        },
        bold24: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
})

export const appTheme = createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                },
                textPrimary: {
                    color: theme.colors.primary,
                },
            },
            defaultProps: {
                variant: 'contained',
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true,
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                },
            },
        },
        MuiFormControl: {
            // styleOverrides: {
            //     root: {},
            // },
            defaultProps: {
                variant: 'outlined',
            },
        },
        // MuiFormControlLabel: {
        //     styleOverrides: {
        //         root: {
        //             margin: '4px 0',
        //         },
        //     },
        // },
    },
})
