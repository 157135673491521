import { createUploadLink } from 'apollo-upload-client'
import {
    ApolloClient,
    ApolloLink,
    from,
    fromPromise,
    Observable,
    NormalizedCacheObject,
    split,
    InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import {
    SubscriptionClient,
    TConnectionParams,
    WebSocketLink,
} from 'graphql-react-subscriptions'

import { API_ROOT, GRAPHQL_PATH, SUBSCRIPTIONS_ROOT } from 'config/env.config'
import { RefreshTokenDocument } from 'api/generated'
import { TokenStorage } from 'services'

const graphQlRoot = `${API_ROOT}${GRAPHQL_PATH}`
const wsRoot = `${SUBSCRIPTIONS_ROOT}${GRAPHQL_PATH}`

const httpLink = createUploadLink({
    uri: graphQlRoot,
})

const ApiLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        credentials: 'include',
        headers: {
            ...headers,
            authorization: TokenStorage.isAuthenticated()
                ? TokenStorage.getAuthenticationHeader()
                : '',
        },
    }))

    return forward(operation)
})

const getNewTokenByRefreshToken = (
    refreshToken: string | null
): Observable<ApolloClient<NormalizedCacheObject>> => {
    return fromPromise(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        client
            .mutate({
                mutation: RefreshTokenDocument,
                variables: { refreshToken: refreshToken as string },
            })
            .then(response => {
                if (response?.data) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const { token, refreshToken: newRefreshToken } =
                        response.data.refreshToken
                    if (token && newRefreshToken) {
                        TokenStorage.storeToken(token)
                        TokenStorage.storeRefreshToken(newRefreshToken)
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        return token
                    }
                }
                return null
            })
            .catch(error => {
                if (
                    error.message === 'Invalid refresh token' ||
                    error.message === 'Refresh token is expired'
                ) {
                    TokenStorage.clear()
                    window.location.href = window.location.origin
                }
            })
    )
}

const wsClient = new SubscriptionClient(`${wsRoot}`, {
    reconnect: true,
    lazy: false,
    inactivityTimeout: 30000,
    connectionParams: (): TConnectionParams => ({
        token: TokenStorage.getToken() || '',
    }),
    connectionCallback: (error): void => {
        const err = error as unknown as Record<string, string>
        if (
            err &&
            err.message &&
            [
                'RuntimeError: Error decoding signature',
                'RuntimeError: Signature has expired',
            ].includes(err.message)
        ) {
            getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
        }
    },
})

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        const messages = graphQLErrors.map(({ message }) => message)
        if (
            messages.includes('Signature has expired') ||
            messages.includes('Error decoding signature')
        ) {
            return getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
                .filter(value => Boolean(value))
                .flatMap(newToken => {
                    const oldHeaders = operation.getContext().headers
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    if (wsClient) {
                        wsClient.close(false, false)
                    }
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `JWT ${newToken}`,
                        },
                    })
                    return forward(operation)
                })
        }
    }
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const wsLink = new WebSocketLink(wsClient)

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    ApiLink.concat(httpLink)
)

const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([errorLink, splitLink]),
})

export { client }
