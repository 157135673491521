import React, { useState } from 'react'

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { Button, Stack, TextField } from '@mui/material'

export interface DateModalProps {
    initialDate: Date
    onSave: (date: Date) => void
}

const DateModal = ({ initialDate, onSave }: DateModalProps): JSX.Element => {
    const [selected, setSelected] = useState<Date>(initialDate)

    return (
        <Stack>
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={selected}
                onChange={value =>
                    setSelected(value ? new Date(value) : new Date())
                }
                renderInput={params => <TextField {...params} />}
            />
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button onClick={() => onSave(selected)}>Save</Button>
            </Stack>
        </Stack>
    )
}
export default DateModal
