import { useQuery } from '@apollo/client'

import {
    ConfigDocument,
    ConfigType,
    ScannerListDocument,
    ScannerType,
} from 'api/generated'

export const useConfig = () => {
    const { data, loading } = useQuery(ConfigDocument, {
        fetchPolicy: 'cache-first',
    })

    return {
        loading,
        data,
    } as {
        loading: true
        data: ConfigType
    }
}

export const useScanners = () => {
    const { data, loading } = useQuery(ScannerListDocument, {
        fetchPolicy: 'cache-first',
    })

    return {
        loading,
        scanners: (data?.scannerList || []) as ScannerType[],
    }
}
