import { makeStyles } from '@mui/styles'

export default makeStyles({
    error: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 999,
    },
    icon: {
        fontSize: '100px',
        color: 'rgba(180,179,179,0.9)',
        marginBottom: '15px',
    },
    text: {
        color: 'rgba(159,158,158,0.9)',
        fontSize: '24px',
    },
})
