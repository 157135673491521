import styles from './index.styles'

import React from 'react'

import cx from 'classnames'
import { Stack, Typography } from '@mui/material'

export interface PageTitleProps {
    title?: string
    children?: JSX.Element
    extraClasses?: { [key: string]: string }
    onClick?: () => void
}
const PageTitle = ({
    title,
    children,
    extraClasses,
    onClick,
}: PageTitleProps): JSX.Element => {
    const classes = styles()

    return (
        <Stack
            className={cx(classes.root, extraClasses?.root)}
            onClick={onClick}
        >
            <Typography
                className={cx(classes.title, extraClasses?.root)}
                variant="h4"
            >
                {title}
            </Typography>
            <Stack className={cx(classes.content, extraClasses?.content)}>
                {children}
            </Stack>
        </Stack>
    )
}
export default PageTitle
